
import { defineComponent } from "vue";
import Captcha from "@/components/Captcha.vue";
export default defineComponent({
  components: { Captcha },
  methods: {
    onVerify(token) {
      (window as any).ReactNativeWebView.postMessage(token);
    },
  },
});
